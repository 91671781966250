<template>
  <vx-card class="pl-3">
    <div class="notification-container">
      <vs-table
        ref="table"
        :sst="true"
        :data="notifications"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
        @sort="handleSort"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between py-4">
          <vs-select
            placeholder="25"
            vs-multiple
            autocomplete
            v-model="dataTableParams.limit"
            :disabled="!serverResponded"
            class="mr-6"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in limitOptions"
              :clearable="false"
            />
          </vs-select>
          <vs-input
            icon="search"
            class="search-input"
            :disabled="!serverResponded"
            v-model="dataTableParams.search"
            @input="handleSearch"
            placeholder="Search..."
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="type">Type</vs-th>
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="message">Message</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            :class="data[indextr].status === 'unread' ? 'unreadTr' : ''"
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
          >
            <vs-td :data="data[indextr].type">{{ data[indextr].type | capitalize}}</vs-td>
            <vs-td :data="data[indextr].title">{{ data[indextr].title }}</vs-td>
            <vs-td :data="data[indextr].message">{{data[indextr].message}}</vs-td>
            <vs-td :data="data[indextr].status">{{data[indextr].status}}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{data[indextr].createdAt | date_formatter}}</vs-td>
            <vs-td :data="data[indextr].type">
              <vx-tooltip text="View/Edit Details">
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="viewDetailHandler(data[indextr]._id, data[indextr].type, data[indextr].metaData)"
                  icon-pack="feather"
                  icon="icon-eye"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="flex justify-end">
        <span class="mr-2"></span>
        <pagination
          class="mt-3"
          :isFetching="!serverResponded"
          :hasPrev="hasPrev"
          :hasNext="hasNext"
          @handleOnChangePage="handleChangePage"/>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import Pagination from "@/views/general-component/pagination.vue";
import { debounce } from "lodash";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
      },
      serverResponded: false,
      notifications: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      loggedInUserType: this.$store.state.AppActiveUser.userType,
      noDataText: "Loading...",
      hasNext: false,
      hasPrev: false
    };
  },
  methods: {
    ...mapActions("general", ["fetchPaginatedNotifications"]),
    getNotificationsList(options = {}) {
      let self = this;
      this.notifications = []
      this.noDataText = "Loading..."
      this.fetchPaginatedNotifications({
        ...self.dataTableParams,
        ...options
      }).then((res) => {
        self.notifications = res.data.data.docs;
        self.hasNext = res.data.data.hasNext
        self.hasPrev = res.data.data.hasPrev
        self.serverResponded = true;
        self.noDataText = "You have no notifications."

        if (options.page) self.dataTableParams.page = options.page
      });
    },
    handleSearch: debounce(function (searchString) {
      this.serverResponded = false;
      this.$refs.table.currentx = 1;
      this.getNotificationsList({
        search: searchString,
        page: 1
      });
    }, 500),
    handleChangePage(page) {
      let payload = {
        next: true,
        id: this.notifications[this.notifications.length - 1]._id,
        page: this.dataTableParams.page + 1
      }

      if (page === 'prev') {
        payload = {
          prev: true,
          id: this.notifications[0]._id,
          page: this.dataTableParams.page - 1
        }
      }

      this.serverResponded = false;
      this.getNotificationsList(payload)
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNotificationsList();
    },
    viewDetailHandler(id, type, metaData) {
      const data = JSON.parse(metaData);
      if (
        type == "AHPRA Expiry Account Disabled" ||
        type == "AHPRA Expiry" ||
        type == "Indemnity Insurance Expiry Account Disabled" ||
        type == "Indemnity Insurance Expiry"
      ) {
        this.$router.push("/doctor/profile");
      } else if (type == "Doctor Pending Treatment Notes") {
        this.$router.push("/doctor/patients/" + data.patientId + "/details");
      } else if (type === "Clinic Join Request") {
        this.$router.push(
          `/${this.$store.state.AppActiveUser.userType}/clinics/${data.clinicId}/respond-clinic-join-request/${data.requestId}`
        );
      } else if (type === "User Registration" || type === "User Created") {
        if (data.userType === "doctor")
          this.$router.push(
            `/${this.loggedInUserType}/doctors/${data.userId}/edit`
          );
        else if (data.userType === "nurse")
          this.$router.push(`/${this.loggedInUserType}/nurses/${data.userId}`);
        else if (data.userType === "clinic")
          this.$router.push(
            `/${this.loggedInUserType}/clinics/${data.userId}/edit`
          );
        else if (data.userType === "franchise")
          this.$router.push(
            `/${this.loggedInUserType}/franchises/${data.userId}`
          );
        else if (data.userType === "subframchise")
          this.$router.push(
            `/${this.loggedInUserType}/franchises/${data.userId}`
          );
        else if (data.userType === "admin")
          this.$router.push(`/${this.loggedInUserType}/admins/${data.userId}`);
      } else if (type === "Account Update Request") {
        if (data.userType === "doctor")
          this.$router.push(
            `/${this.loggedInUserType}/doctors/${data.userId}/profile-update-review`
          );
        else if (data.userType === "nurse")
          this.$router.push(
            `/${this.loggedInUserType}/nurses/${data.userId}/profile-update-review`
          );
      } else if (
        type === "Clinic Invoice Payment Succeeded" ||
        type === "Clinic Invoice Payment Failed"
      ) {
        this.$router.push(`/${this.loggedInUserType}/invoice`);
      } else if (
        type === "Doctor Payout Succeeded" ||
        type === "Doctor Payout Failed"
      ) {
        this.$router.push(`/${this.loggedInUserType}/doctor-aggregated-report`);
      }

      this.readNotification(id);
    },
    readNotification(id) {
      this.$store
        .dispatch("general/readNotifications", id)
        .then((response) => {})
        .catch((err) => console.log(err));
    },
  },
  watch: {
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNotificationsList();
      }
    },
  },
  created() {
    this.loggedInUserType =
      this.$store.state.AppActiveUser.userType === "superAdmin"
        ? "super-admin"
        : this.$store.state.AppActiveUser.userType;
    this.getNotificationsList();
  },
};
</script>

<style lang="scss">
.notification-container {
  tr.unreadTr {
    background: #f0f8ff;
  }

  .search-input {
    .vs-input--input {
      border-radius: 20px;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
      }
    }

    .vs-con-input {
      .vs-input--icon {
        top: auto;
        left: 8px;
        border-right: none;
      }
    }
  }
}
</style>
